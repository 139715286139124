import { Fragment, useState } from "react";
import { Trans as Translate } from 'react-i18next';
import classNames from "classnames";
import { strofeApi } from "../../api/strofeApi";

import Form from 'react-bootstrap/Form';
import StrofeCoin from "../../icons/coins/StrofeCoin";

import './Coupon.scss';
import CurveBorder from './coupon_curve_border.png';
import ScissorsIcon from "../../icons/ScissorsIcon";

export default function Coupon({coupon, setCoupon}) {
  const [promoCode, setPromoCode]       = useState('');
  const [displayError, setDisplayError] = useState(false);

  const redeemCoupon = async () => {
    try {
      setDisplayError(false);
      const { data } = await strofeApi.get(`/stripe_coupons/${promoCode}`);
      setCoupon(data);
    }
    catch {
      setDisplayError(true);
    }
  }

  return (
    <div className="__coupon">
        <div className={classNames('tag-container', { redeemed: coupon !== null })}>
          <div className='tag-curve-border' style={{ backgroundImage: `url(${CurveBorder})`}} />
          <div className='tag'>
            <div className='corner corner-top-right' />
            <div className='corner corner-bottom-right' />
            <div className='amount'>
              { coupon !== null && <><Translate i18nKey='x-br-percent-off' values={{ percent: coupon.percent_off }} /></>}
            </div>
          </div>
        </div>
        <div className='content-curve-border-mobile' style={{ backgroundImage: `url(${CurveBorder})`}} />
        <div className='content'>
          <div className='content-inner'>
            <div className='title'>
              <StrofeCoin width={48} height={48} />
              <div><Translate>Redeem Coupon</Translate></div>
            </div>
            { coupon === null &&
              <>
                <div className='description'>
                  <Form.Control value={promoCode} onChange={e => setPromoCode(e.target.value)} />
                </div>
                <div className='redeem-btn-container'>
                  <button className='redeem-btn' onClick={redeemCoupon}><ScissorsIcon /> <Translate>Redeem Coupon</Translate></button>
                </div>
              </>
            }
            { coupon != null &&
              <>
                <div className='description'>
                  {coupon.name}
                </div>
                <div className='redeem-btn-container'>
                  <div className='redeemed'><Translate>Coupon Redeemed</Translate></div>
                </div>
              </>
            }
            <div className='valid-until'>{displayError && <Translate>coupon-load-error</Translate>}</div>
          </div>

          <div className='corner corner-top-left' />
          <div className='corner corner-bottom-left' />
        </div>
        <div className='content-curve-border' style={{ backgroundImage: `url(${CurveBorder})`}} />
        { (false) && <div className='expired'><Translate>Expired</Translate></div> }
    </div>
  );
}
