import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { strofeApi } from '../../api/strofeApi';
import { CardExpiryElement, CardNumberElement, CardCvcElement, useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { Trans as Translate, useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { usersActions } from '../../store/usersSlice';
import { EventTracker } from '../../utils/Tracking';
import { PRICING_TIER } from '../../utils/Prices';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import LoadingOverlay from '../../modals/LoadingOverlay';

import './PaymentModal.scss';

const stripeStyle = {
  base: {
    fontSize: '16px',
    color: '#424770',
    '::placeholder': {
      color: '#bbbbbb',
    },
  },
  invalid: {
    color: '#9e2146',
  },
};

// const pricingTier = 'pro', 'premium'

export default function PaymentModal({ show, onHide, pricingTier, onPaymentSuccessful, showFreeTrial, currentUser, overrideFreeTrial, cadence, coupon }) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState();
  const [creatingSubscription, ] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [updateSubscriptionError, setUpdateSubscriptionError] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const pricingTierRef = useRef(pricingTier);

  const paypalInvoiceId = useRef();

  const loading = creatingSubscription || processingPayment;
  const loadingPhrase = creatingSubscription ? "Loading..." : "Processing...";

  const showError = updateSubscriptionError;

  useEffect(() => {
    pricingTierRef.current = pricingTier;
  }, [pricingTier]);

  useEffect(() => {
    if (pricingTier === null || paymentRequest === null){
      return;
    }

    let amount = parseFloat(PRICING_TIER[pricingTier].price) * 100
    if (coupon !== null) {
      amount = amount * (100 - coupon.percent_off) / 100
    }

    paymentRequest && paymentRequest.update({
      total: {
        label: PRICING_TIER[pricingTier].phrase,
        amount: amount
      }
    });
  }, [pricingTier, paymentRequest]);

  const subscriptionType = PRICING_TIER[pricingTier]?.type === 'membership';
  const handleCreatePaypal = async () => {
    const { data } = await strofeApi.post('/invoices', { invoice: { price_id: PRICING_TIER[pricingTier].id, invoice_type: 'paypal' } });
    paypalInvoiceId.current = data.id;

    return data.paypal_token;
  }

  const handleApprovePaypal = async () => {
    // Nomodo API will validate the order (capture)
    const { data } = await strofeApi.put(`/invoices/${paypalInvoiceId.current}`);
    
    try {
      // re-fetch user to update coins counter
      await dispatch(usersActions.fetchById({ id: currentUser.id }));
  
      if (data.status === 'active' || data.status === 'paid') {
        const value = parseFloat(PRICING_TIER[pricingTier].price);
        EventTracker.purchase({ transaction_id: paypalInvoiceId.current, value, currency: "USD" });
    
        onPaymentSuccessful();
      }
      else {
        setUpdateSubscriptionError(true); 
      }
    }

    catch (error) {
      setUpdateSubscriptionError(true);
      console.log(updateSubscriptionError);
    }
  }

  // Subscriptions are created on free_trial = true with a payment_method, or
  // or before payment when free_trial = false
  const createSubscription = async (trial = false, charge_automatically = false) => {
    const subscription = {
      subscription: {
        price_id : PRICING_TIER[pricingTierRef.current].id,
        trial,
        charge_automatically,
        coupon: coupon !== null ? coupon.id : null
      }
    };

    const response = await strofeApi.post('/subscriptions/', subscription);

    return response.data;
  }

  // After a successful free trial, invoice, or subscription, follow the succesful payment flow:
  const successfulPaymentFlow = async trial_end => {
    await dispatch(usersActions.fetchById({ id: currentUser.id }));
        
    // EventTracker.purchase();
    
    onPaymentSuccessful(trial_end);
    setProcessingPayment(false);
  }

  useEffect(() => {
    if (!stripe || !elements || paymentRequest) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: '',
        amount: 0,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then(result => {
      if (result) {
        setPaymentRequest(pr);
      }
    });

    pr.on('paymentmethod', async (e) => {
      try {
        await strofeApi.put('/users/payment_method', { user: {
          payment_method: e.paymentMethod.id
        }});

        const subscription = await createSubscription(
          false,
          true,
        );

        e.complete('success');
        successfulPaymentFlow(null);
      } catch (error) {
        setProcessingPayment(false);
        setErrorMessage(error.message);
        e.complete('fail');
      }
    });
  }, [stripe, elements, paymentRequest, pricingTier, successfulPaymentFlow]);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    // Either CardElement (includes also postal code) or CardNumberElement:
    const cardElement = elements.getElement(CardNumberElement);

    // Use your card Element with other Stripe.js APIs
    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: cardElement,
    // });

    setProcessingPayment(true);

    // Helper functions:

    // For free trials, get the secret for Stripe creating a payment_method:
    const createPaymentMethod = async () => {
      const response = await strofeApi.post('/users/payment_method');
      return response.data;
    }

    // Once a Stripe card is verified (went throough), the payment_method is updated:
    const updatePaymentMethod = async payment_method => {
      await strofeApi.put('/users/payment_method', { user: {
        payment_method
      }});
    }

    // Subscriptions are updated after a valid Stripe card_payment:
    const updateSubscription = async payment_method => {
      const subscription = { subscription: { payment_method } };
      const response = await strofeApi.put(`/subscriptions/${currentUser.id}`, subscription);
      return response.data;
    }

    // Create an invoice before a Stripe payment:
    const createInvoice = async () => {
      const invoice = { invoice: {
        price_id : PRICING_TIER[pricingTier].id,
      }};

      const response = await strofeApi.post('/invoices', invoice);

      return response.data;
    }

    // Invoices need to be updated after a valid Stripe card payment:
    const updateInvoice = async invoice_id => {
      const { data } = await strofeApi.put(`/invoices/${invoice_id}`);
      return data;
    }

    // Setup a card when creating a free trial (card will not be charged):
    const stripeCardSetup = async secret => {
      const { error, setupIntent } = await stripe.confirmCardSetup(secret, { payment_method:
        { card: cardElement }
      });

      if (error) {
        console.log('[error]', error);
        setErrorMessage(error.message);
        setProcessingPayment(false);
      }
      else {
        return setupIntent.payment_method;
      }
    }

    // A card will be confirmed as charged on invoices (single purchase) or non-free-trial subscriptions:
    const stripeCardPayment = async secret => {
      const { error, paymentIntent } = await stripe.confirmCardPayment(secret, { payment_method: {
        card: cardElement
      }});

      if (error) {
        console.log('[error]', error);
        setErrorMessage(error.message);
        setProcessingPayment(false);

        return null;
      }

      return paymentIntent.payment_method;
    }

    // PAYMENT FLOWS - HAPPENS AFTER CLICKING ON "PAY" WITH STRIPE
    /// ----------------------------------------------------------

    // Free Trial (card is created, validated, but not charged)
    // overrideFreeTrial allows the Pricing page to set a trial (IE: based on the result of a user ab test)
    if ((PRICING_TIER[pricingTier].freeTrial || overrideFreeTrial) && showFreeTrial) {
      // 1. Create a payment method for Stripe to confirm the card setup:
      let userPayment;

      try {
        userPayment = await createPaymentMethod();
      }
      catch {
        setErrorMessage('There is an issue processing your payment with our servers. Your card was not charged.');
        setProcessingPayment(false);

        return;
      }

      // 2. Confirm the card works and a valid payment_method will be returned:
      const payment_method = await stripeCardSetup(userPayment.client_secret);
      
      // 3. Use the payment method for that user:
      if (payment_method) {
        try {
          await updatePaymentMethod(payment_method);

          // 4. Create a subscription now that we have a valid payment method
          // (without a valid payment method, a user could create a trialing subscription with an invalid card):
          if (payment_method) {
            const { status, trial_end } = await createSubscription(true);
            
            if (status === 'trialing') {
              successfulPaymentFlow(trial_end);
            }
            
            else {
              throw new Error ("Invoice could not be updated or wrong status");
            }
          }
        }

        catch {
          setUpdateSubscriptionError(true); 
          setProcessingPayment(false);
        }
      }
    }

    // Regular Subscription (immediately charged):
    else if (subscriptionType) {
      // 1. Create a payment method for Stripe to confirm the card setup:
      let userPayment;

      try {
        userPayment = await createPaymentMethod();
      }
      catch {
        setErrorMessage('There is an issue processing your payment with our servers. Your card was not charged.');
        setProcessingPayment(false);

        return;
      }

      // 2. Confirm the card works and a valid payment_method will be returned:
      const payment_method = await stripeCardSetup(userPayment.client_secret);
      
      // 3. Use the payment method for that user:
      if (payment_method) {
        try {
          await updatePaymentMethod(payment_method);

          // 4. Create a subscription now that we have a valid payment method
          // (without a valid payment method, a user could create a trialing subscription with an invalid card):
          if (payment_method) {
            const { status, trial_end } = await createSubscription(false, true);
            
            if (status === 'active') {
              successfulPaymentFlow(trial_end);
            }
            
            else {
              throw new Error ("Invoice could not be updated or wrong status");
            }
          }
        }
        catch {
          setUpdateSubscriptionError(true); 
          setProcessingPayment(false);
        }
      }
    }

    // Regular Invoice (one-time payment):
    else {
      // 1. create (post) /invoice
      let invoice;
      
      try {
        invoice = await createInvoice();
      }
      catch {
        setErrorMessage('There is an issue processing your payment with our servers. Your card was not charged.');
        setProcessingPayment(false);

        return;
      }

      // 2. stripe confirms and charges the card:
      const payment_method = await stripeCardPayment(invoice.client_secret);

      // 3. invoice was paid succesfully, update (put) /invoice
      try {
        if (payment_method) {
          const { status } = await updateInvoice(invoice.id);

          if (status === 'paid') {
            successfulPaymentFlow();
          }
          else {
            throw new Error ("Invoice could not be updated or wrong status");
          }
        }
      }

      catch {
        setUpdateSubscriptionError(true); 
        setProcessingPayment(false);  
      }
    }
  };

  const tier = PRICING_TIER[pricingTier];
  const price = tier?.price;
  const tierPhrase = tier?.phrase;

  const price_m = tier?.price_m;
  
  const canDoTrial = ((tier?.freeTrial || overrideFreeTrial) && showFreeTrial);
  const subscriptionPhrase = canDoTrial ? 'subscription-price-free-trial' : 'subscription-price-cancellation';

  return (
    <>
      <Modal className='__modal __payment-modal' size='sm' backdrop='static' show={show && !showError}>
        <LoadingOverlay show={loading} phrase={loadingPhrase} />
        <Form onSubmit={handleSubmit} className={classNames('payment-form', {'form-hidden': loading })}>
          <Modal.Body>
            <div className='details'>
              {tierPhrase ? <div>{ tierPhrase }</div> : <div><Translate i18nKey='x-strofe-coins' values={{ amount: tier?.coins.toLocaleString() }} /></div> }
              <div className='cost'>${price_m || price} {tier?.type === 'membership' && <span>{ t('/ mo') }</span> }</div>
            </div>

            { tier?.type === 'membership' && (
              <p>
                <Translate i18nKey={subscriptionPhrase} values={{ price, cadence }} />
              </p>
            )}

            { coupon !== null && (
              <p>
                <Translate i18nKey='subscription-with-coupon' values={{ coupon: coupon.name }} />
              </p>
            )}

            <div><Translate i18nKey='payment-details'/></div>

            {paymentRequest && <div className="third-party-payments"> 
                <PaymentRequestButtonElement options={{paymentRequest}} />
                <div className="separator"><span><Translate>Or pay with a card</Translate></span></div>
              </div>
            }

            { process.env.NODE_ENV === 'development' && false && (
              <pre className='mt-4 small'>
                TEST CARD: <span style={{ userSelect: 'all' }}> 4242 4242 4242 4242</span><br />
                3D SECURE: <span style={{ userSelect: 'all' }}> 4000 0027 6000 3184</span><br/>
                RENEW FAIL: <span style={{ userSelect: 'all' }}>4000 0000 0000 0341</span>
              </pre>
            )}
            
            <div className='card-number'>
              <CardNumberElement options={{ style: stripeStyle, showIcon: true }} />
            </div>

            <div className='date-cvc-container'>
              <div className='expiry-date'>
                <CardExpiryElement options={{ style: stripeStyle }} />
              </div>
              <div className='cvc'>
                <CardCvcElement options={{ style: stripeStyle }} />
              </div>
            </div>

            { errorMessage && <Alert variant='danger'>{ errorMessage }</Alert> }
          </Modal.Body>

          <Modal.Footer>
            <Button variant='secondary' onClick={onHide} data-test="PAYMENT-close"><Translate>Cancel</Translate></Button>
            <Button type='submit' disabled={!stripe}><Translate i18nKey={canDoTrial ? 'Start Trial' : 'Subscribe Now'} /></Button>

            { !loading && tier?.type !== 'membership' && (
              <div className='paypal-container'>
                <div className='pay-with-paypal'><Translate>Or pay with PayPal</Translate></div>
                <PayPalButtons createOrder={handleCreatePaypal} onApprove={handleApprovePaypal} fundingSource='paypal' />
              </div>
            )}
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showError} className='__modal' size='sm' backdrop='static'>
        <Modal.Header>
          <Translate>Oops!</Translate>
        </Modal.Header>
        <Modal.Body>
          <p>Your payment was processed successfully, but there was a problem with our servers.</p>
          <p>If your purchase or subscription is not reflected, get in touch with us through the Feedback button.</p>
        </Modal.Body>
        <Modal.Footer>
          <a className="btn btn-primary" href="/settings" role="button"><Translate>Back to Strofe</Translate></a>
        </Modal.Footer>
      </Modal>
    </>
  );
}
