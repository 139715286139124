const TRANSLATIONS_ES = {
  'Get started' : 'Empezar',
  'Login'       : 'Iniciar Sesión',
  'Cancel'      : 'Cancelar',
  'Strofe'      : 'Strofe',
  'Loading'     : 'Cargando',
  'Loading...'  : 'Cargando...',
  'Edit'        : 'Editar',
  'Continue'    : 'Continuar',
  'Back'        : 'Volver',
  'Terms'               : "Términos",
  'Privacy Policy'      : "Política de privacidad",
  'Delete Track'        : "Eliminar pieza",
  'Delete'              : "Eliminar",
  'Music track deleted' : "La pieza ha sido eliminada",

  'Make Your Own Music In Seconds': "Crea tu propia música en segundos",

  // Login Modal
  // -----------
  'Email address'          : 'Correo electrónico',
  'Password'               : 'Contraseña',
  'Forgot password?'       : '¿Olvidaste tu contraseña?',
  'Log In to Strofe'       : 'Iniciar sesión en Strofe',
  'Continue with Facebook' : 'Continuar con Facebook',

  'Already have an account? Log In' : '¿Ya tienes una cuenta? Ingresa aquí',


  // Navigation
  'Library'             : 'Biblioteca',
  'Create'              : 'Crear',
  'Transaction History' : "Historial de transacciones",
  'Get Strofe Coins'    : "Adquirir Strofe Coins",
  'Strofe Coins'        : "Strofe Coins",
  'Support Center'      : "Soporte",
  'User Stats'          : "Estadísticas de los usuarios",
  'Release Version'     : "Versión actual",
  'Print Audio Log'     : "Imprimir el Audio Log",
  'Coupon Manager'      : "Administrador de cupones",
  'Strofe Ambassador'   : "Embajador de Strofe",

  // Music
  // -----
  'Spooky'                       : 'Tenebroso',
  'Sad'                          : 'Triste',
  'Chill'                        : 'Chill',
  'Happy'                        : 'Feliz',
  'Spirited'                     : 'Enérgico',
  'Select a mood for your track' : 'Selecciona un ánimo para tu pieza',
  'Music Style'                  : 'Estilo musical',
  'Auto'                         : 'Auto',
  'Customize'                    : 'Personalizar',
  'Jazz'                         : 'Jazz',
  'Bossa Nova'                   : 'Bossa Nova',
  'Reggae'                       : 'Reggae',
  'Orchestral Pop'               : 'Pop Orquestal',
  'Retro Futuristic'             : 'Retro Futurista',
  'Lofi'                         : 'Lofi',
  'Pop'                          : 'Pop',
  'Epic'                         : 'Épico',
  'Oddity'                       : 'Rareza',
  'Hip Hop'                      : 'Hip Hop',
  'Latin'                        : 'Latino',
  'Anime'                        : 'Anime',
  'Your new, unique track will be ready in seconds...' : 'Tu nueva pieza única estará lista en unos segundos...',

  "Questions or feedback?"       : '¿Preguntas o comentarios?',
  "Let us know!"                 : '¡Contáctanos!',
  'Logout'                       : 'Cerrar Sesión',

  // Editor
  'Make Public'                  : 'Publicar',
  'Regenerate instrument'          : 'Regenerar instrumento',
  'Regenerate Track'               : 'Regenerar Pieza',
  'Please wait...'                 : 'Espera un momento...',
  'Regenerating Track'             : 'Regenerando',
  'Solo'                           : 'Solo',
  'Regenerating...'                : 'Regenerando...',
  'Download'                       : 'Descargar',
  'Instrument Pack'                : "Paquete de instrumentos",
  'Loading Track...'               : "Cargando pieza...",
  'Reroll'                         : "Tirar los dados",
  'Reroll Track'                   : "Tirar los dados",
  "Let's try again!"               : "¡Volvamos a intentarlo!",
  'Refresh'                        : "Refrescar",

  // Library
  'Title'            : 'Título',
  'Modified'         : 'Modificada',
  'Style'            : 'Estilo',
  'Mood'             : 'Ánimo',
  'Edit Track'       : 'Editar',
  'Share'            : 'Compartir',
  'Duplicate'        : 'Duplicar',
  'Track duplicated' : 'Pieza duplicada',
  'Loading Tracks...' : 'Cargando piezas...',
  'Automatic'         : "Automático",
  'Filter'            : 'Filtrar',
  'All'               : 'Todas',
  'Genre'             : 'Género',
  'Favorites'         : 'Favoritas',
  'Custom Filter'     : 'Filtro personalizado',
  'Purchased'         : 'Compradas',
  'Showing purchased tracks only. <1>Remove filter.</1>' : "Mostrando únicamanete piezas adquiridas. <1>Remover filtro.</1>",
  'Showing favorite tracks only. <1>Remove filter.</1>'  : "Mostrando únicamente piezas favoritas. <1>Remover filtro.</1>",
  'Showing filtered results. <1>Remove filter.</1>'      : "Mostrando resultados filtrados. <1>Remover filtro.</1>",
  'Date modified (newest first)'                         : "Fecha (nuevas primero)",
  'Date modified (oldest first)'                         : "Fecha (antiguas primero)",
  'Title (ascending)'                                    : "Título (ascendiente)",
  'Title (descending)'                                   : "Título (descendiente)",
  'Track duration (shortest first)'                      : "Duración (más cortas primero)",
  'Track duration (longest first)'                       : "Duración (más largas primero)",
  'Your library is empty.'                               : "Tu biblioteca está vacía.",
  'There are no tracks that match the current filter.'   : "No existen piezas que coincidan con el filtro seleccionado.",
  'Copy Link'                                            : "Copiar enlace",

  // Settings
  // --------
  'Settings'                            : 'Configuración',
  'Change password'                     : 'Cambiar contraseña',
  'Email'                               : 'Correo electrónico',
  'Display name'                        : 'Nombre mostrado',
  'Verify Your Email'                   : 'Verifica tu correo',
  'Sending verification code...'        : 'Enviando código de verificación...',
  'Verify'                              : 'Verificar',
  'Verifying...'                        : 'Verificando...',
  'Re-send Verification Code'           : 'Re-enviar código de verificación',
  'Current password'                    : 'Contraseña actual',
  'Confirm your password'               : 'Confirma tu contraseña',
  'New password'                        : 'Nueva contraseña',
  'Confirm new password'                : 'Confirma la nueva contraseña',
  'Update'                              : 'Actualizar',
  'New email address'                   : 'Nuevo correo electrónico',
  'Your password has been updated'      : 'Tu contraseña ha sido actualizada',
  'Your email address has been updated' : 'Tu correo electrónico ha sido actualizado',
  'Your email is verified'              : 'Tu correo electrónico está verificado',
  'Updating profile...'                 : 'Actualizando perfil...',
  'Language'                            : 'Lenguaje',
  'Your display name has been updated'  : 'Tu nombre mostrado ha sido actualizado',
  'Click here to verify your email'     : 'Haz click aquí para verificar tu correo electrónico',
  'Update Password'                     : 'Actualizar Contraseña',
  'Update Email'                        : 'Actualizar Correo Electrónico',
  'Update Display Name'                 : 'Actualizar Nombre Mostrado',

  // Landing Page
  // ------------
  'lp-what-is-strofe' : "Strofe le abre la puerta a todos a crear música con el poder de la inteligencia artificial.",
  'lp-purpose'        : "Crea música que música que se adapte el estilo de tu video juego, stream, video de YouTube, podcast, y más. Toma ventaja de las herramientas de masterización para personalizar tu música.",
  'lp-simple-tool'    : "Cada pieza compuesta con Strofe es única y libre de preocupaciones de licencia.",
  'lp-easy-usage'     : "Si eres un productor musical experimentado que busca una herramienta útil o simplemente exploras la música por primera vez, Strofe está aquí para ayudarte. ¡Estás a unos clics de tu primera o milésima canción!",
  'lp-mood-title'     : "¡ELIJE UN ÁNIMO, GÉNERO MUSICAL, Y LISTO!",

  // Login Modal
  // -----------
  'invalid-email-password': "Tu combinación de correo/contraseña no es válida.",

  // Feedback
  // --------
  'feedback-success-1': '¡Gracias por tus sugerencias!',
  'feedback-success-2': 'Te contactaremos a través del correo electrónico.',

  // Songs
  // -----
  'delete-song-warning'  : "¿Estás seguro de que deseas eliminar esta pieza? Una vez borrada, no puede ser recuperada.",
  'track-load-error'     : "Esta pieza no fue encontrada o no tienes acceso a ella.",
  'track-load-mp3-error' : "Hubo un error cargando esta pieza. Recarga esta página para intentarlo de nuevo.",

  // Create Song
  // -----------
  'reroll-modal-description' : 'Volver a "tirar los dados" te permite crear una nueva pieza con la misma personalización.',

  // Library
  // -------
  'trackname-copy': "{{trackname}} - copia",

  // Feedback form
  // -------------
  'Submit a bug or feedback'      : "Enviar comentario o reportar pulga",
  'Feedback Type'                 : "Tipo de comentario",
  'How can we help?'              : "¿Cómo podemos ayudarte?",
  'Description'                   : "Descripción",
  'Submit'                        : "Enviar",
  'Provide us with a description' : "Ingresa una descripción",
  'Close'                         : "Cerrar",

  // Payment
  // -------
  // eslint-disable-next-line
  'subscription-price-cancellation' : "Se te cobrará una tarifa mensual de ${{price}}. Puedes cancelar la subscripción en cualquier momento.",
  'subscription-with-coupon'        : "Tu primer mes es completamente gratis gracias al cupón \"${{coupon}}\" que has aplicado. No se te cobrará hasta el segundo mes.",
  // eslint-disable-next-line
  'pay-price'                   : "Pagar ${{price}}",
  'Processing...'               : "Procesando...",
  'Purchase'                    : "Adquirir",
  'Download Track'              : "Descargar pieza",
  'Purchase Track'              : "Adquirir pieza",
  'Hang on tight!'              : "Espera un segundo!",
  'Generating...'               : "Generando...",
  'Download MIDI'               : "Descargar MIDI",
  'Download MP3'                : "Descargar MP3",
  'Download WAV'                : "Descargar WAV",
  'Purchase Track (MP3)'        : "Adquirir pieza (MP3)",
  'Purchase Track (MP3 + MIDI)' : "Adquirir pieza (MP3 + MIDI)",
  'Add MIDI format'             : "Agregar formato MIDI",
  'Current Balance'             : "Balance actual",
  'Final Balance'               : "Balance final",
  'Get Track'                   : "Adquirir pieza",
  'Congrats on your new track!' : "¡Felicidades por tu nueva pieza!",
  'Track (MIDI)'                : "Pieza (MIDI)",
  'Reload'                      : "Recargar",

  // Get coins
  'get-track-coins'       : "¡Adquiere esta pieza utilizando Strofe Coins!",
  'get-track-description' : "Una vez adquirida, esta pieza te pertenece. ¡Puedes usarla como quieras, donde quieras, cuando quieras!",
  'get-midi-coins'        : "¡Adquiere el archivo MIDI de esta pieza utilizando Strofe Coins!",
  'get-midi-description'  : "Descarga el archivo MIDI para cargarlo en tu estación de audio digital.",
  'confirm-purchase'      : "Aquí está el desglose de tu balance después de esta transacción:",
  'not-enough-coins'      : "¡Oh no! No tienes suficientes Strofe Coins para adquirir esta pieza.",
  'you-need-x-coins'      : "Actualmente tienes {{balance}} Strofe Coins y necesitas {{missing}} más para adquirir esta pieza.",
  'x-strofe-coins'        : "{{amount}} Strofe Coins",
  
  'Your track is ready for download.'                           : "Tu pieza está lista para ser descargada.",
  'Your song is being processed, this will take a few seconds.' : "Tu pieza está siendo procesada en estos momentos.",

  'payment-details'       : "Método de pago",
  'invite-instructions'   : "Una vez que hayas elegido tu nombre mostrado, utiliza tu enlace de invitación personalizado y recibirás <strong>240 Strofe Coins</strong> cada vez que alguien lo use para crear una cuenta:",
  'own-x-tracks_one'      : "Adquiere y sé dueño de {{count}} pieza¹",
  'own-x-tracks_other'    : "Adquiere y sé dueño de {{count}} piezas¹",
  'purchase-using-coins'  : "Adquiere esta pieza usando Strofe Coins, y haz lo que quieras con ella.",

  'ways-earn-coins'        : "¡Hay muchas formas de adquirir Strofe Coins de gratis! Aquí hay algunas formas para hacerlo:",
  'create-account-steps'   : "Adquiere <strong>119 Strofe Coins</strong> ingresando un correo y una contraseña.",
  'verify-email-steps'     : "Gana <strong>120 Strofe Coins</strong>, simplemente ingresa el código de verificación que enviamos a tu correo. Si ingresaste por medio de otro método (como Facebook o Discord), tu correo se ha verificado automáticamente.",
  'daily-song-steps'       : "Cada día que crees una nueva piezas acumulas Strofe Coins, <strong>1 el primer día, 2 el segundo, luego 4... así hasta 16,</strong> lo cual continua diariamente mientras sigas creando piezas.",
  'new-ways-get-coins'     : "* ¡Constantemente estamos agregando nuevas maneras de ganar y usar tus Strofe Coins!",
  'coin-balance-summary'   : "Aquí está el resumen de tu balance:",

  'faq-commercial-purpose' : "<p>Cualquier pieza que adquieras puede ser utilizada de cualquier forma que desees y en cualquier proyecto comercial, sin necesidad de mencionar a Strofe.</p>",
  'faq-what-are-coins' : "<p>Strofe Coins son la moneda virtual que puedes utilizar para comprar los derechos de las piezas que creas en Strofe.com. Compra piezas en masa para adquirir descuentos o adquiérelas de forma gratuita de diferentes formas:</p>",
  'faq-track-cost'     : "<p>La pieza en formato MP3 de alta calidad cuesta 240 Strofe coins, y en formato MIDI 200. Puedes adquirir ambas por un descuento único de 400 Strofe coins.</p>",
  'faq-edit-track'     : "<p>¡Sin duda! Puedes modificar la pieza ilimitadamente utilizando el editor y volver a descargar la pieza.</p>",
  'faq-ownership'      : "<p>La pieza será tuya para siempre, y eres libre de editarla y modificarla como desees.</p>",  
  'faq-coins-expire'   : "<p>Las Strofe Coins siempre estarán disponibles dentro de tu cuenta, así que puedes adquirir más Strofe Coins en descuento y usarlas luego.</p>",
  'faq-strofe-ai'      : "<p>Miles de piezas son analizadas para crear piezas únicas que a la vez suenan familiar a tu oído.</p>",  
  'faq-tracks-unique'  : "<p>Para ciertos estilos y géneros, puede que una pieza suene similar a otra, pero es virtualmenete imposible crear dos piezas iguales aun cuando existan millones de piezas generadas por medio de Strofe. ¡Esa es la belleza de la inteligencia artificial!</p>",
  'faq-updates'        : "<p>Constantemente estamos mejorando Strofe, agregando instrumentos de mejor calidad; la buena noticia es que todas las piezas que hayas creado tendrán estas mejoras en el futuro incluidas.</p>",
  
  // Settings
  // --------
  'email-verification-regex-error'   : "Tu código de verificación contiene 6 números sin letras ni caractéres especiales.",
  'email-verification-request-error' : "Tu código de verificación es inválido o ha expirado.",
  'confirm-password-error'           : "Tu nueva contraseña no concuerda con la confirmación.",
  'current-password-error'           : "La contraseña no concuerda con tu contraseña actual.",
  'display-name-taken'               : "El nombre mostrado que elegista ya está en uso.",
  'display-name-blank-error'         : "El nombre mostrado no puede estar en blanco.",
  'email-taken'                      : "Este correo electrónico no está disponible.",
  'Profile'                          : "Perfil",
  "You're a Strofe Ambassador!"      : "¡Eres un Embajador de Strofe!",
  'Delete Your Account'              : "Eliminar tu cuenta",
  'Delete Account'                   : "Eliminar cuenta",
  'profile-last-updated-date'        : "Última actualización del perfil: {{date}}",
  'name-or-email-taken'              : "El nombre que has escogido o el correo están asociados a un usuario.",
  'ambassador-detail'                : "Por tu esfuerzo dedicado a promover y ayudar a Strofe, puedes adquirir y descargar piezas de gratis.",
  'Back to Strofe'                   : "Volver a Strofe",

  'Your account has been permanently deleted.' : "Tu cuenta ha sido eliminada de forma permanente.",

  // Coin Transactions
  // -------
  'Song Creation'            : "Creación de una pieza",
  'Total'                    : "Total",
  'Strofe Coin Transactions' : "Transacciones de Strofe Coins",
  'Date'                     : "Fecha",
  'Transaction'              : "Transacción",
  'Amount'                   : "Cantidad",
  'Song Purchase'            : "Compra de pieza",
  'Email Verification'       : "Verificación del correo",
  'Sign Up'                  : "Registrarse",
  'Loading Transactions...'  : "Cargando transacciones...",
  'Coupon Redemption'        : "Redención de cupón",
  'Coin Purchase'            : "Compra de Strofe Coins",
  'Song Like'                : '"Me gusta" a tu pieza',
  'Past Day'                 : 'Último día',
  'Past 7 Days'              : 'Últimos 7 días',
  'Past 30 Days'             : 'Últimos 30 días',
  'Past Year'                : 'Último año',
  'Credits'                  : 'Créditos',
  'Debits'                   : 'Débitos',
  'my-balance'               : 'Mi balance:',
  'no-transactions-filter'   : "No hay transacciones disponibles para la fecha y filtros seleccionados.",
  // eslint-disable-next-line
  'transaction-amount-x'     : "Monto de la transacción: ${{cost}}",

  'A user joined Strofe using your invite link' : "Un nuevo usuario se registra con tu enlace de invitación",

  // Purchase Page
  // -------------
  'What are Strofe Coins?'       : "¿Qué son las Strofe Coins?",
  'Create an account'            : "Crear una cuenta",
  'Verify your email'            : "Verificar tu correo",
  'Make a song every day!'       : "¡Crea una pieza diariamente!",
  'Payment Successful'           : "Pago procesado exitosamente",
  'Thank you for your purchase!' : "¡Gracias por tu compra!",
  'Previous Balance'             : "Balance anterior",
  "Today's Purchase" : "Compra de hoy",

  'Set your display name'                : "Escoge tu nombre desplegado",
  'Link copied to clipboard'             : "Enlace copiado a la papelera",
  'Sale'                                 : "Venta",
  'Day'                                  : "Día",
  'Days'                                 : "Días",
  'Hour'                                 : "Hora",
  'Hours'                                : "Horas",
  'Minute'                               : "Minuto",
  'Minutes'                              : "Minutos",
  'Seconds'                              : "Segundos",
  '50% Off Strofe Coins'                 : "50% descuento en Strofe Coins",
  'Summer Sale'                          : "Oferta de verano",
  'Own Your Music'                       : "Sé el propietario de tu música",
  'Winter Sale'                          : "Oferta de invierno",
  'Sale 50% Off'                         : "50% de descuento",
  'Or pay with PayPal'                   : "O paga con PayPal",
  'Buy Strofe Coins'                     : "Compra Strofe Coins",
  'Offer ends May 31st.'                 : "Oferta válida hasta el 31 de mayo.",
  '50% Off All MAY'                      : "50% de descuento en Mayo",
  'Try out the new Anime Style!'         : "¡Prueba el nuevo estilo anime!",
  
  'Purchase Strofe Coins for Half our Regular Price.'    : "Adquiere Strofe Coins a mitad de precio.",
  'Help grow the community by inviting users to Strofe' : "Ayuda a nuestra comunidad a crecer invitando a tus amigos a usar Strofe",
  '<0>After you acquire a track, its copyright and distribution rights are yours, forever. <1>Learn more.</1></0>': "<0>Después de adquirir una pieza, los derechos de autor y de distribución serán tuyos para siempre. <1>Más información.</1></0>",
  
  // Coupons
  // -------
  'coupon-valid-until'               : "—Válido antes del {{valid_until}}—",
  'Redeem Coupon'                    : "Redimir cupón",
  'You already redeemed this coupon' : "Ya haz redimido este cupón",
  'x-br-strofe-coins'                : "{{amount}}<br />Strofe Coins",
  'x-br-percent-off'                 : "{{percent}}%<br />Primer Mes",
  'coupon-load-error'                : "Este cupón ha expirado o no es válido.",
  'redeem-error'                     : "El servidor ha tenido problemas o ya haz redimido este cupón anteriormente.",
  'redeem-error-refresh'             : "Refresca esta página o contáctanos si este error persiste.",
  'previously-redeemed'              : "Ya has redimido este cupón anteriormente y obtenido las Strofe Coins.",
  'Coupon Redeemed'                  : "Cupón redimido",
  'Expired'                          : "Expirado",
  'Oops!'                            : "¡Oh no!",
  'Your coupon has been redeemed!'   : "¡Tu cupón ha sido redimido!",

  // Comments
  // --------
  'x-comments_one'        : "{{count}} comentario",
  'x-comments_other'      : "{{count}} comentarios",
  'song-url'              : "Dirección de la pieza: {{url}}",
  'report-comment-id'     : "ID del comentario: {{id}}",
  'view-x-replies_one'    : "Ver {{count}} respuesta",
  'view-x-replies_other'  : "Ver {{count}} respuestas",
  'set-display-name'      : "Para comentar en esta pieza, escoge un nombre primero:",
  'delete-comment-body'   : "Estás seguro que deseas borrar este comentario?",
  'delete-nested-replies' : "Todas las respuestas anidadas también serán eliminadas.",

  'Write a comment...'                  : "Escribir un comentario...",
  'Comment'                             : "Comentar",
  'Report'                              : "Reportar",
  'Reply'                               : "Responder",
  'View more replies'                   : "Ver más respuestas",
  'Comments are disabled on this track' : "Los comentarios han sido deshabilitados en esta pieza",
  'Delete Comment'                      : "Borrar comentario",

}

export default TRANSLATIONS_ES;
